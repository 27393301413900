.react-calendar {
  width: 300px;
  max-width: 100%;
  background: white;
  box-shadow: 0 10px 20px 0 rgba(23, 114, 112, 0.2);
  border-radius: 10px;
  border: none;
  padding: 10px;
  padding-bottom: 50px;
}
.react-calendar__navigation__label__labelText {
  font-size: 16px;
  font-weight: bold;
  color: #314143;
}
.react-calendar button {
  width: 40px;
  height: 40px;
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__tile--active {
  background-color: #00bbb5 !important;
}
.react-calendar__tile--rangeStart {
  border-radius: 5px 0px 0px 5px;
  background-color: #008884 !important;
}
.react-calendar__tile--rangeEnd {
  border-radius: 0px 5px 5px 0px;
  background-color: #008884 !important;
}
.react-calendar__tile--rangeBothEnds {
  border-radius: 0px 0px 0px 0px;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 0px;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover {
  background-color: #f7f9f9;
}
.react-calendar__navigation button:enabled:focus {
  background-color: #f7f9f9;
}
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  font-size: 38px;
  margin-top: -3px !important;
  color: #00bbb5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  width: 11px;
  height: 17px;
  font-family: 'Noto Sans Japanese';
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #68878d;
}
.react-calendar__month-view__weekdays__weekday abbr[title] {
  text-decoration: none;
}
.react-calendar__year-view .react-calendar__tile {
  padding: 0em 0.5em;
}
.react-calendar__decade-view .react-calendar__tile {
  padding: 0em 0.5em;
}
.react-calendar__century-view .react-calendar__tile {
  padding: 0em 0.5em;
}
.react-calendar__tile {
  color: #142c2b;
}
.react-calendar__tile.highlight__week:enabled:hover {
  background-color: #f7f9f9 -highlight-active;
}
.react-calendar__tile.highlight__week:enabled:focus {
  background-color: #f7f9f9 -highlight-active;
}
.react-calendar__tile {
  background-color: white;
}
.react-calendar__tile:disabled {
  background-color: #f4f4f4;
}
.react-calendar__tile:enabled:hover {
  background-color: #f7f9f9;
}
.react-calendar__tile:enabled:focus {
  background-color: #f7f9f9;
}
.react-calendar__month-view__days__day--weekend {
  color: #314143;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: rgba(20, 44, 43, 0.2);
}
.highlight__week {
  background-color: #00bbb5 !important;
  color: white;
}
.highlight__week:disabled {
  background-color: #f7f9f9 -highlight-disabled !important;
}
.highlight__week:enabled:hover {
  background-color: #f7f9f9 -highlight-active !important;
}
.highlight__week:enabled:focus {
  background-color: #f7f9f9 -highlight-active !important;
}
.highlight__week__start__day {
  border-radius: 5px 0px 0px 5px;
}
.highlight__week__end__day {
  border-radius: 0px 5px 5px 0px;
}
.react-calendar__tile--now {
  box-shadow: 0 0 5px 0 rgba(23, 114, 112, 0.4);
  background: #008884 !important;
  color: white;
  border-radius: 50%;
}
.react-calendar__tile--now:enabled:hover {
  background-color: #f7f9f9 -highlight-active !important;
}
.react-calendar__tile--now:enabled:focus {
  background-color: #f7f9f9 -highlight-active !important;
}
.react-calendar__tile--hasActive {
  background: #00bbb5;
  color: white;
}
.react-calendar__tile--hasActive:enabled:hover {
  background: #00d5ce;
}
.react-calendar__tile--hasActive:enabled:focus {
  background: #00d5ce;
}
.react-calendar__tile--active {
  background: #00bbb5;
  color: white;
}
.react-calendar__tile--active.highlight__week:enabled:hover {
  background-color: #f7f9f9 -highlight-active !important;
}
.react-calendar__tile--active.highlight__week:enabled:focus {
  background-color: #f7f9f9 -highlight-active !important;
}
